import Vue from 'vue'
import VueRouter from 'vue-router'
import AppContainer from '@/views/Main/AppContainer'

/* Default title tag */
const defaultDocumentTitle = 'Rubbishman'

Vue.use(VueRouter)

const routes = [
  {
    meta: {
      title: 'Dashboard'
    },
    path: '/',
    component: AppContainer,
    children: [
      {
        meta: {
          title: 'Dashboard'
        },
        path: '',
        name: 'dashboard',
        component: () => import('@/views/Main/Dashboard')
      },
      {
        meta: {
          title: 'Routes'
        },
        path: 'routes',
        component: {
          render: (c) => c('router-view')
        },
        children: [
          {
            meta: {
              title: 'Routes'
            },
            path: '',
            name: 'routesList',
            component: () => import('@/views/Main/Routes/List')
          },
          {
            meta: {
              title: 'Create Route'
            },
            path: 'create',
            name: 'routeCreate',
            component: () => import('@/views/Main/Routes/Create')
          },
          {
            meta: {
              title: 'Route Information'
            },
            path: 'view/:id',
            name: 'routeInfo',
            component: () => import('@/views/Main/Routes/Info')
          }
        ]
      },
      {
        meta: {
          title: 'Bins'
        },
        path: 'bins',
        component: {
          render: (c) => c('router-view')
        },
        children: [
          {
            meta: {
              title: 'Bins'
            },
            path: '',
            name: 'binsList',
            component: () => import('@/views/Main/Bins/List')
          }
        ]
      },
      {
        meta: {
          title: 'Customers'
        },
        path: 'customers',
        component: {
          render: (c) => c('router-view')
        },
        children: [
          {
            meta: {
              title: 'Customers'
            },
            path: '',
            name: 'customersList',
            component: () => import('@/views/Main/Customers/List')
          },
          {
            meta: {
              title: 'New Customer'
            },
            path: 'create',
            name: 'customerCreate',
            component: () => import('@/views/Main/Customers/Create')
          },
          {
            meta: {
              title: 'Customer Information'
            },
            path: 'view/:id',
            name: 'customerInfo',
            component: () => import('@/views/Main/Customers/Info')
          }
        ]
      },
      {
        meta: {
          title: 'Drivers'
        },
        path: 'drivers',
        component: {
          render: (c) => c('router-view')
        },
        children: [
          {
            meta: {
              title: 'Drivers'
            },
            path: '',
            name: 'driversList',
            component: () => import('@/views/Main/Drivers/List')
          },
          {
            meta: {
              title: 'New Driver'
            },
            path: 'create',
            name: 'createDriver',
            component: () => import('@/views/Main/Drivers/Create')
          },
          {
            meta: {
              title: 'Driver Information'
            },
            path: 'view/:id',
            name: 'driverInfo',
            component: () => import('@/views/Main/Drivers/Info')
          }
        ]
      },
      {
        meta: {
          title: 'Settings'
        },
        path: 'settings',
        component: {
          render: (c) => c('router-view')
        },
        children: [
          {
            meta: {
              title: 'Bin Assessments'
            },
            path: 'bin-assessments',
            name: 'binAssessments',
            component: () => import('@/views/Main/Settings/BinAssessments')
          },
          {
            meta: {
              title: 'Bin Services'
            },
            path: 'bin-services',
            name: 'binServices',
            component: () => import('@/views/Main/Settings/BinServices')
          },
          {
            meta: {
              title: 'Bin Sizes'
            },
            path: 'bin-sizes',
            name: 'binSizes',
            component: () => import('@/views/Main/Settings/BinSizes')
          },
          {
            meta: {
              title: 'Villages'
            },
            path: 'villages',
            name: 'villages',
            component: () => import('@/views/Main/Settings/Villages')
          }
        ]
      }
    ]
  },
  {
    meta: {
      title: 'Login'
    },
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "tables" */ '../views/Login.vue')
  },
  {
    meta: {
      title: 'Page not found'
    },
    path: '/404',
    name: '404',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "tables" */ '../views/NotFound.vue')
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  // check first if page exists
  if (!router.resolve(to).resolved.matched.length) {
    next({ name: '404' })
    return
  }

  // check if it is being logged out due to unauthorized
  if (to.name === 'login' && to.query.error === 'unauthorized') {
    localStorage.removeItem('token')
    next({ name: 'login' })
    return
  }

  const token = localStorage.getItem('token')
  // if trying to go to login if it is already logged in retain user
  if (to.name === 'login' && token) next({ name: '' })
  // else if not login and trying to navigate to dashboard
  else if (to.name !== 'login' && !token) next({ name: 'login' })
  // else let it proceed
  else next()
})

/* Collapse mobile aside menu on route change & set document title from route meta */
router.afterEach(to => {
  if (to.meta && to.meta.title) {
    document.title = `${to.meta.title} — ${defaultDocumentTitle}`
  } else {
    document.title = defaultDocumentTitle
  }
})

export default router
