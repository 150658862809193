import axios from 'axios'
import router from '@/router'

const instance = axios.create({
  baseURL: 'https://www.rubbishman-api.com/'
  // baseURL: 'http://[::]:8080'
})

// Add a request interceptor
instance.interceptors.request.use(config => {
  const token = localStorage.getItem('token')
  if (token) config.headers.Authorization = `Bearer ${token}`
  return config
}, error => {
  return Promise.reject(error)
})

// Add a response interceptor
instance.interceptors.response.use(response => {
  return response.data
}, error => {
  console.log('response error ->', error.response)

  if (error.response) {
    const { status, data } = error.response
    if (status === 401) {
      router.replace({ name: 'login', query: { error: 'unauthorized' } })
    }

    if (data) return Promise.reject(data)
  }

  return Promise.reject(new Error('Something went wrong'))
})

export default instance
