<template>
  <div id="app">
    <nav-bar />
    <div class="route-container">
      <router-view />
    </div>
    <aside-menu :menu="menu" />
    <footer-bar />
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar'
import AsideMenu from '@/components/AsideMenu'
import FooterBar from '@/components/FooterBar'

export default {
  name: 'Home',
  components: {
    FooterBar,
    AsideMenu,
    NavBar
  },
  computed: {
    menu () {
      return [
        'Menus',
        [
          {
            to: '/',
            label: 'Dashboard',
            icon: 'chart-pie'
          },
          {
            to: '/routes',
            label: 'Routes',
            icon: 'road-variant'
          },
          // {
          //   to: '/bins',
          //   label: 'Bins',
          //   icon: 'delete'
          // },
          {
            to: '/customers',
            label: 'Customers',
            icon: 'account-group'
          },
          {
            to: '/drivers',
            label: 'Drivers',
            icon: 'car'
          },
          {
            label: 'Settings',
            subLabel: 'Settings',
            icon: 'settings',
            menu: [
              {
                to: '/settings/bin-assessments',
                label: 'Bin Assessments',
                icon: 'delete'
              },
              {
                to: '/settings/bin-sizes',
                label: 'Bin Sizes',
                icon: 'delete'
              },
              {
                to: '/settings/bin-services',
                label: 'Bin Services',
                icon: 'delete'
              },
              {
                to: '/settings/villages',
                label: 'Villages',
                icon: 'home'
              }
            ]
          }
        ]
      ]
    }
  },
  created () {
    this.$store.commit('user/user', {
      name: 'John Doe',
      email: 'john@example.com',
      avatar: 'https://avatars.dicebear.com/v2/gridy/John-Doe.svg'
    })
  }
}
</script>

<style scoped>
  .route-container {
    min-height: calc(100vh - 93px);
  }
</style>
