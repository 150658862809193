import lookups from '@/services/lookups'

import MUTATIONS from '@/store/mutations/lookups'

const {
  // bin mutations
  SET_BIN_TYPES,
  SET_BIN_SERVICES,
  SET_BIN_SIZES,
  SET_BIN_ASSESSMENTS,

  SET_BIN_TYPES_MAPPINGS,
  SET_BIN_SERVICES_MAPPINGS,
  SET_BIN_SIZES_MAPPINGS,
  SET_BIN_ASSESSMENTS_MAPPINGS,

  // customer mutations
  SET_CUSTOMER_STATUSES,
  SET_CUSTOMER_STATUSES_MAPPINGS,
  SET_CUSTOMER_VILLAGES,
  SET_CUSTOMER_VILLAGES_MAPPINGS
} = MUTATIONS

const state = {
  // bin lookups
  binTypes: [],
  binServices: [],
  binSizes: [],
  binAssessments: [],

  // mapping used for the UI
  binTypesMapping: {},
  binServicesMapping: {},
  binSizesMapping: {},
  binAssessmentsMapping: {},

  // customer lookups
  customerStatuses: [],
  customerStatusesMapping: {},
  customerVillages: [],
  customerVillagesMapping: {}
}

const getters = {
  // bin getters
  binTypes: ({ binTypes }) => binTypes,
  binServices: ({ binServices }) => binServices,
  binSizes: ({ binSizes }) => binSizes,
  binAssessments: ({ binAssessments }) => binAssessments,

  // bin mappings
  binTypesMapping: ({ binTypesMapping }) => binTypesMapping,
  binServicesMapping: ({ binServicesMapping }) => binServicesMapping,
  binSizesMapping: ({ binSizesMapping }) => binSizesMapping,
  binAssessmentsMapping: ({ binAssessmentsMapping }) => binAssessmentsMapping,

  // customer getters
  customerStatuses: ({ customerStatuses }) => customerStatuses,
  customerStatusesMapping: ({ customerStatusesMapping }) => customerStatusesMapping,
  customerVillages: ({ customerVillages }) => customerVillages,
  customerVillagesMapping: ({ customerVillagesMapping }) => customerVillagesMapping
}

const actions = {
  // bin lookups
  async getBinTypes ({ commit }) {
    const types = await lookups.getBinTypes()
    const temp = {}
    types.map(({ code, label }) => (temp[code] = label))

    commit(SET_BIN_TYPES, types)
    commit(SET_BIN_TYPES_MAPPINGS, temp)
  },
  async getBinServices ({ commit }) {
    const services = await lookups.getBinServices()
    const temp = {}
    services.map(({ code, label }) => (temp[code] = label))

    commit(SET_BIN_SERVICES, services)
    commit(SET_BIN_SERVICES_MAPPINGS, temp)
  },
  async getBinSizes ({ commit }) {
    const sizes = await lookups.getBinSizes()
    const temp = {}
    sizes.map(({ code, label }) => (temp[code] = label))

    commit(SET_BIN_SIZES, sizes)
    commit(SET_BIN_SIZES_MAPPINGS, temp)
  },
  async getBinAssessments ({ commit }) {
    const assessments = await lookups.getBinAssessments()
    const temp = {}
    assessments.map(({ code, label }) => (temp[code] = label))

    commit(SET_BIN_ASSESSMENTS, assessments)
    commit(SET_BIN_ASSESSMENTS_MAPPINGS, temp)
  },

  // customer lookups
  async getCustomerStatuses ({ commit }) {
    const statuses = await lookups.getCustomerStatuses()
    const temp = {}
    statuses.map(({ code, label }) => (temp[code] = label))

    commit(SET_CUSTOMER_STATUSES, statuses)
    commit(SET_CUSTOMER_STATUSES_MAPPINGS, temp)
  },

  async getCustomerVillages ({ commit }) {
    const villages = await lookups.getCustomerVillages()
    const temp = {}
    villages.map(({ code, label }) => (temp[code] = label))

    commit(SET_CUSTOMER_VILLAGES, villages)
    commit(SET_CUSTOMER_VILLAGES_MAPPINGS, temp)
  }
}

const mutations = {
  [SET_BIN_TYPES]: (state, payload) => (state.binTypes = payload),
  [SET_BIN_TYPES_MAPPINGS]: (state, payload) => (state.binTypesMapping = payload),

  [SET_BIN_SERVICES]: (state, payload) => (state.binServices = payload),
  [SET_BIN_SERVICES_MAPPINGS]: (state, payload) => (state.binServicesMapping = payload),

  [SET_BIN_SIZES]: (state, payload) => (state.binSizes = payload),
  [SET_BIN_SIZES_MAPPINGS]: (state, payload) => (state.binSizesMapping = payload),

  [SET_BIN_ASSESSMENTS]: (state, payload) => (state.binAssessments = payload),
  [SET_BIN_ASSESSMENTS_MAPPINGS]: (state, payload) => (state.binAssessmentsMapping = payload),

  [SET_CUSTOMER_STATUSES]: (state, payload) => (state.customerStatuses = payload),
  [SET_CUSTOMER_STATUSES_MAPPINGS]: (state, payload) => (state.customerStatusesMapping = payload),

  [SET_CUSTOMER_VILLAGES]: (state, payload) => (state.customerVillages = payload),
  [SET_CUSTOMER_VILLAGES_MAPPINGS]: (state, payload) => (state.customerVillagesMapping = payload)
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
