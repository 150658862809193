import lookups from '@/api/lookups'

// bin lookups
export const getBinTypes = () => {
  return lookups.getBinTypes()
}

export const getBinServices = () => {
  return lookups.getBinServices()
}

export const addBinService = (data) => {
  return lookups.addBinService(data)
}

export const updateBinService = (code, data) => {
  return lookups.updateBinService(code, data)
}

export const deleteBinService = (code) => {
  return lookups.deleteBinService(code)
}

export const getBinSizes = () => {
  return lookups.getBinSizes()
}

export const addBinSize = (data) => {
  return lookups.addBinSize(data)
}

export const updateBinSize = (code, data) => {
  return lookups.updateBinSize(code, data)
}

export const deleteBinSize = (code) => {
  return lookups.deleteBinSize(code)
}

export const getBinAssessments = () => {
  return lookups.getBinAssessments()
}

export const addBinAssessment = (data) => {
  return lookups.addBinAssessment(data)
}

export const updateBinAssessment = (code, data) => {
  return lookups.updateBinAssessment(code, data)
}

export const deleteBinAssessment = (code) => {
  return lookups.deleteBinAssessment(code)
}

// customer lookups
export const getCustomerStatuses = () => {
  return lookups.getCustomerStatuses()
}

export const getCustomerVillages = () => {
  return lookups.getCustomerVillages()
}

export const addVillage = (data) => {
  return lookups.addVillage(data)
}

export const updateVillage = (code, data) => {
  return lookups.updateVillage(code, data)
}

export const deleteVillage = (code) => {
  return lookups.deleteVillage(code)
}

export default {
  getBinTypes,

  getBinServices,
  addBinService,
  updateBinService,
  deleteBinService,

  getBinSizes,
  addBinSize,
  updateBinSize,
  deleteBinSize,

  getBinAssessments,
  addBinAssessment,
  updateBinAssessment,
  deleteBinAssessment,

  getCustomerStatuses,

  getCustomerVillages,
  addVillage,
  updateVillage,
  deleteVillage
}
