import axios from './instance'

// bin lookups
export const getBinTypes = () => {
  return axios.get('/lookups/bin/types')
}

export const getBinServices = () => {
  return axios.get('/lookups/bin/services')
}

export const addBinService = (data) => {
  return axios.post('/lookups/bin/service', data)
}

export const updateBinService = (code, data) => {
  return axios.put(`/lookups/bin/service/${code}`, data)
}

export const deleteBinService = (code) => {
  return axios.delete(`/lookups/bin/service/${code}`)
}

export const getBinSizes = () => {
  return axios.get('/lookups/bin/sizes')
}

export const addBinSize = (data) => {
  return axios.post('/lookups/bin/size', data)
}

export const updateBinSize = (code, data) => {
  return axios.put(`/lookups/bin/size/${code}`, data)
}

export const deleteBinSize = (code) => {
  return axios.delete(`/lookups/bin/size/${code}`)
}

export const getBinAssessments = () => {
  return axios.get('/lookups/bin/assessments')
}

export const addBinAssessment = (data) => {
  return axios.post('/lookups/bin/assessment', data)
}

export const updateBinAssessment = (code, data) => {
  return axios.put(`/lookups/bin/assessment/${code}`, data)
}

export const deleteBinAssessment = (code) => {
  return axios.delete(`/lookups/bin/assessment/${code}`)
}

// customer lookups
export const getCustomerStatuses = () => {
  return axios.get('/lookups/customer/statuses')
}

export const getCustomerVillages = () => {
  return axios.get('/lookups/customer/villages')
}

export const addVillage = (data) => {
  return axios.post('/lookups/customer/village', data)
}

export const updateVillage = (code, data) => {
  return axios.put(`/lookups/customer/village/${code}`, data)
}

export const deleteVillage = (code) => {
  return axios.delete(`/lookups/customer/village/${code}`)
}

export default {
  getBinTypes,

  getBinServices,
  addBinService,
  updateBinService,
  deleteBinService,

  getBinSizes,
  addBinSize,
  updateBinSize,
  deleteBinSize,

  getBinAssessments,
  addBinAssessment,
  updateBinAssessment,
  deleteBinAssessment,

  getCustomerStatuses,

  getCustomerVillages,
  addVillage,
  updateVillage,
  deleteVillage
}
