<template>
<aside v-show="isAsideVisible" class="aside is-placed-left is-expanded">
    <div class="logo">
      <div class="logo-container">
        <figure class="image is-100x100">
          <img src="@/assets/rubbishmanlogo.png">
        </figure>
      </div>
      <div class="logo-container">
        <figure class="image is-100x100">
          <img src="@/assets/mrrubbishman.png">
        </figure>
      </div>
    </div>
    <div class="menu is-menu-main">
      <template v-for="(menuGroup, index) in menu">
        <p v-if="typeof menuGroup === 'string'" :key="index" class="menu-label">
          {{ menuGroup }}
        </p>
        <aside-menu-list
          v-else
          :key="index"
          :menu="menuGroup"
          @menu-click="menuClick"
        />
      </template>
    </div>
  </aside>
</template>

<script>
import { mapState } from 'vuex'
import AsideMenuList from '@/components/AsideMenuList'

export default {
  name: 'AsideMenu',
  components: { AsideMenuList },
  props: {
    menu: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState('user', {
      isAsideVisible: state => state.isAsideVisible
    })
  },
  methods: {
    menuClick (item) {
      //
    }
  }
}
</script>

<style scoped>
  .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .logo {
    padding: 10px;
    display: flex;
    flex-direction: column;
    background-color: #17191E;
  }
</style>
