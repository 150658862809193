<template>
  <router-view />
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'App',
  async created () {
    try {
      await Promise.all(this.loadLookups())
    } catch (error) {
      this.$buefy.snackbar.open({
        message: 'Error loading lookups',
        queue: false
      })
    }
  },
  methods: {
    ...mapActions('lookups', [
      'getBinTypes',
      'getBinServices',
      'getBinSizes',
      'getBinAssessments',
      'getCustomerStatuses',
      'getCustomerVillages'
    ]),
    loadLookups () {
      return [
        this.getBinTypes(),
        this.getBinServices(),
        this.getBinSizes(),
        this.getBinAssessments(),
        this.getCustomerStatuses(),
        this.getCustomerVillages()
      ]
    }
  }
}
</script>
