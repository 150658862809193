export default {
  // bin mutations
  SET_BIN_TYPES: 'SET_BIN_TYPES',
  SET_BIN_SERVICES: 'SET_BIN_SERVICES',
  SET_BIN_SIZES: 'SET_BIN_SIZES',
  SET_BIN_ASSESSMENTS: 'SET_BIN_ASSESSMENTS',

  SET_BIN_TYPES_MAPPINGS: 'SET_BIN_TYPES_MAPPINGS',
  SET_BIN_SERVICES_MAPPINGS: 'SET_BIN_SERVICES_MAPPINGS',
  SET_BIN_SIZES_MAPPINGS: 'SET_BIN_SIZES_MAPPINGS',
  SET_BIN_ASSESSMENTS_MAPPINGS: 'SET_BIN_ASSESSMENTS_MAPPINGS',

  // customer mutations
  SET_CUSTOMER_STATUSES: 'SET_CUSTOMER_STATUSES',
  SET_CUSTOMER_STATUSES_MAPPINGS: 'SET_CUSTOMER_STATUSES_MAPPINGS',
  SET_CUSTOMER_VILLAGES: 'SET_CUSTOMER_VILLAGES',
  SET_CUSTOMER_VILLAGES_MAPPINGS: 'SET_CUSTOMER_VILLAGES_MAPPINGS'
}
